import shiftForWebsiteTimezoneDisplay from 'shared/utils/shiftForWebsiteTimezoneDisplay';
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/events-collection.css';
/**
 * Module that caters to the Javascript needs of Events Collections.
 *
 * @module  squarespace-events-collection
 */

YUI.add('squarespace-events-collection', function(Y) {

  /**
   * Plugin for the list view of the Events Collection. Binds window resize and tweak
   * update events.
   *
   * @class       ListViewPlugin
   * @extends     Plugin.Base
   * @namespace   Squarespace.EventsCollection
   * @constructor
   */
  Y.namespace('Squarespace.EventsCollection').ListViewPlugin =
  Y.Base.create('eventsCollectionListViewPlugin', Y.Plugin.Base, [], {

    initializer: function() {

      this.set('thumbnails', this.get('host').all('.eventlist-column-thumbnail img[data-src]'));

      this.get('thumbnails').each(function(img) {
        img.plug(Y.Squarespace.Loader2, { load: true, mode: 'fill' });
      });

      this._syncUI();
      this._bindUI();

    },

    destructor: function() {

      if (this._resizeEvent) {
        this._resizeEvent.detach();
      }
      if (this._tweakChangeEvent) {
        this._tweakChangeEvent.detach();
      }
      if (this._tweakResetEvent) {
        this._tweakResetEvent.detach();
      }

    },

    _bindUI: function() {

      this._resizeEvent = Y.on('windowresize', function() {
        this._syncUI();
      }, Y.config.win, this);

      this._bindTweakEvents();

    },

    _syncUI: function() {

      this.get('thumbnails').each(function(img) {
        img.fire('refresh');
      });

    },

    _bindTweakEvents: function() {

      this._tweakChangeEvent = Y.Global.on('tweak:change', function (f) {

        var tweak = f.getName();

        if (
          tweak === 'event-thumbnails' ||
          tweak === 'event-thumbnail-size' ||
          tweak === 'event-list-compact-view' ||
          tweak === 'event-show-past-events'
        ) {
          this._syncUI();
        }

      }, this);

      this._tweakResetEvent = Y.Global.on('tweak:reset', function (f) {
        this._syncUI();
      }, this);

    }

  }, {

    NS: 'eventsCollection'

  });

  Y.Squarespace.EventsCollection.initializeListView = function(listEl) {
    listEl.plug(Y.Squarespace.EventsCollection.ListViewPlugin);
  };

  Y.Squarespace.EventsCollection.initializeCalendarView = function(calendarEl) {
    var queryString = Y.QueryString.parse(Y.config.win.location.search.substring(1));
    var monthAndYear;

    if (queryString.month) {
      monthAndYear = queryString.month;
      var month = monthAndYear.substring(0, 2);
      var parsed = Y.config.win.parseInt(month, 10);
      var isMonthANumber = !Y.config.win.isNaN(parsed);
      var format = isMonthANumber ? 'MM-YYYY' : 'MMMM-YYYY';
      monthAndYear = moment(monthAndYear, format).toDate();
    } else {
      monthAndYear = shiftForWebsiteTimezoneDisplay(new Date()); // today, as per the website's time zone.
    }

    new Y.Squarespace.EventsCollectionCalendar({
      contentBox: calendarEl,
      collectionId: calendarEl.getAttribute('data-collection-id'),
      date: monthAndYear,
      category: queryString.category || '',
      tag: queryString.tag || '',
      author: queryString.author || '',
      render: true
    }).plug(Y.Squarespace.SquarespaceCalendarRenderer);
  };

  Y.config.win.Squarespace.onInitialize(Y, function() {

    // Plug into Events Collection list view
    if (Y.one('.sqs-events-collection-list')) {
      Y.all('.sqs-events-collection-list').each(Y.Squarespace.EventsCollection.initializeListView);
    }

    // Initialize Events Collection calendar view
    if (Y.one('.sqs-events-collection-calendar')) {

      Y.all('.sqs-events-collection-calendar').each(Y.Squarespace.EventsCollection.initializeCalendarView);
    }
  });

  Y.config.win.Squarespace.onDestroy(Y, function() {

    // Plug into Events Collection list view
    if (Y.one('.sqs-events-collection-list')) {
      Y.all('.sqs-events-collection-list').each(function(listEl) {
        listEl.unplug(Y.Squarespace.EventsCollection.ListViewPlugin);
      });
    }

    // Initialize Events Collection calendar view
    if (Y.one('.sqs-events-collection-calendar')) {
      Y.all('.sqs-events-collection-calendar').each(function(calendarEl) {

        var widget = Y.Widget.getByNode(calendarEl);
        if (!widget) {
          if (__DEV__) {
            console.warn('Found a calendar wrapper that doesnt have a widget bound to it!');
          }
          return;
        }

        widget.destroy();

      });
    }

  });


  /**
   * Backwards Compatibility: This allows calendars being initialized by Dev Platform sites that are using the old
   * system classes to be initialized without error by the new classes.
   *
   * @class       EventCollectionCalendar
   * @extends     Base
   * @namespace   Squarespace
   * @constructor
   */
  Y.namespace('Squarespace').EventCollectionCalendar =
  Y.Base.create('eventCollectionCalendar', Y.Widget, [], {

    render: function() {

      new Y.Squarespace.EventsCollectionCalendar({
        contentBox: this.get('contentBox'),
        collectionId: Y.config.win.Static.SQUARESPACE_CONTEXT.collectionId,
        render: true
      }).plug(Y.Squarespace.SquarespaceCalendarRenderer);

    }

  },
  {
    ATTRS: {}
  });


}, '1.0', {
  requires: [
    'base',
    'node',
    'squarespace-calendar-core-renderer',
    'squarespace-events-collection-calendar',
    'squarespace-image-loader'
  ]
});
