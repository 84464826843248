import moment from 'moment';

/**
 * @module squarespace-events-collection-calendar
 */
YUI.add('squarespace-events-collection-calendar', function(Y) {

  /**
   * Extension of base Squarespace Calendar class, which extends YUI Calendar
   * Adds functionality for deep linking (history)

   * @class       EventsCollectionCalendar
   * @extends     SquarespaceCalendar
   * @namespace   Squarespace
   * @constructor
   */
  var EventsCollectionCalendar =
  Y.namespace('Squarespace').EventsCollectionCalendar =
  Y.Base.create('eventsCollectionCalendar', Y.Squarespace.SquarespaceCalendar, [], {

    initializer: function() {
      this.set('_history', new Y.History());
      this._addHistory();
    },

    destructor: function() {
      this._navClickEvent.detach();
      this._historyChangeEvent.detach();
    },

    bindUI: function() {

      EventsCollectionCalendar.superclass.bindUI.call(this);

      // Save state when navigating between months
      this._navClickEvent = this.get('contentBox').delegate('click', this._addHistory,
        '.yui3-calendarnav-prevmonth, .yui3-calendarnav-nextmonth', this);

      // Load the saved state when navigating between months with the browser back/fwd buttons
      this._historyChangeEvent = Y.on('history:change', function(e) {
        if (e.src === Y.HistoryHTML5.SRC_POPSTATE && e.newVal.month) {
          this.set('date', e.newVal.month);
        }
      }, this);

    },

    _addHistory: function() {
      var date = this.get('date');
      var queryString = this._buildQueryStringForHistoryUrl(date);
      var url = Y.config.win.location.pathname + queryString;
      this.get('_history').addValue('month', date, { url: url });
    },

    /**
     * Build the query string that will be used for saving states and loading saved states.
     *
     * @method  _buildQueryStringForHistoryUrl
     */
    _buildQueryStringForHistoryUrl: function(date) {
      var formattedDate = moment(date).locale('en').format('MM-YYYY');
      var category = this.get('category');
      var tag = this.get('tag');
      var author = this.get('author');

      var queryString =
        '?view=calendar' +
        '&month=' + formattedDate +
        (category ? '&category=' + category : '') +
        (tag ? '&tag=' + tag : '') +
        (author ? '&author=' + author : '');

      return queryString;
    },

    /**
     * Allow users to select and interact with text within calendar cells, instead of not allowing them to do that.
     * Override of a Y.Calendar method.
     *
     * @override
     * @method  _preventSelectionStart
     */
    _preventSelectionStart: null

  },
  {
    ATTRS: {}
  });

}, '1.0', {
  requires: [
    'base',
    'history',
    'node',
    'squarespace-calendar-core-renderer',
    'squarespace-util'
  ]
});
